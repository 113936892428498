import { Component, ViewEncapsulation } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonList,
  IonSpinner,
  IonTitle,
  IonToggle,
  IonToolbar,
  ModalController,
  Platform,
  ToastController,
} from '@ionic/angular/standalone';
import { HeaderComponent } from '../../../components/header/header.component';
import { AuthService, LoginCredentials } from '../../../services/auth/auth.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { UserService } from '../../../services/user/user.service';
import { addIcons } from 'ionicons';
import { eye, eyeOff } from 'ionicons/icons';
import { Keyboard } from '@capacitor/keyboard';

@Component({
  selector: 'app-login-jwt-expired',
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [
    FormsModule,
    IonButton,
    IonContent,
    IonFooter,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonList,
    IonSpinner,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonToggle,
    TranslateModule,
    HeaderComponent,
  ],
  templateUrl: './login-jwt-expired.component.html',
  styleUrl: './login-jwt-expired.component.scss',
})
export class LoginJwtExpiredComponent {
  account: LoginCredentials = {
    username: '',
    password: '',
    rememberMe: false,
  };

  public loading = false;
  public showPassword: boolean = false;
  protected readonly onsubmit = onsubmit;
  // Our translated text strings
  private loginErrorString: string;
  private loginNetworkError: string;

  constructor(
    public modalCtrl: ModalController,
    public toastCtrl: ToastController,
    public translateService: TranslateService,
    public user: UserService,
    public platform: Platform,
    public authService: AuthService,
  ) {
    addIcons({ eye, eyeOff });
  }

  ngOnInit() {
    this.translateService.get('LOGIN_ERROR').subscribe((value) => {
      this.loginErrorString = value;
    });

    this.translateService.get('LOGIN_NETWORK_ERROR').subscribe((value) => {
      this.loginNetworkError = value;
    });
  }

  async doLogin() {
    this.account.username = this.account.username.trim();
    this.account.password = this.account.password.trim();
    this.loading = true;
    if (this.platform.is('capacitor')) {
      await Keyboard.hide();
      this.account.rememberMe = true;
    }

    try {
      const loggedIn = await this.authService.login(this.account);
      if (loggedIn) {
        // Logged in user
        this.loading = false;
        try {
          await this.modalCtrl.dismiss();
        } catch (err) {
          console.error(err);
        }
      } else {
        this.loading = false;
        const toast = await this.toastCtrl.create({
          message: this.loginErrorString,
          duration: 3000,
          position: 'top',
          swipeGesture: 'vertical',
        });
        await toast.present();
      }
    } catch (err) {
      // Unable to log in
      this.loading = false;
      this.account.password = '';
      const toast = await this.toastCtrl.create({
        message: this.loginNetworkError,
        duration: 3000,
        position: 'top',
        swipeGesture: 'vertical',
      });
      await toast.present();
    }
  }
}
