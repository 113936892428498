<app-header [isModal]="true" [modalDismissable]="false" title="RENEW_SESSION"></app-header>

<ion-content class="ion-padding">
  <form (keyup.enter)="doLogin()" (submit)="doLogin()">
    <ion-list class="list list-md">
      <ion-item class="item item-md text-input">
        <ion-input
          [(ngModel)]="account.username"
          [placeholder]="'EMAIL' | translate"
          appAutofill
          autocomplete="email"
          class="input input-md"
          name="username"
          style="text-align: left"
          type="email"
        ></ion-input>
      </ion-item>
      <ion-item class="item item-md text-input">
        <ion-input
          [(ngModel)]="account.password"
          [placeholder]="'PASSWORD' | translate"
          [type]="showPassword ? 'text' : 'password'"
          appAutofill
          autocomplete="current-password"
          class="input input-md"
          id="password"
          name="password"
          style="text-align: left"
        >
          <ion-button (click)="showPassword = !showPassword" aria-label="Show/hide" fill="clear" slot="end">
            <ion-icon [name]="showPassword ? 'eye' : 'eye-off'" aria-hidden="true" slot="icon-only"></ion-icon>
          </ion-button>
        </ion-input>
      </ion-item>
      @if (!platform.is('capacitor')) {
        <ion-item class="item item-md remember-me">
          <ion-toggle [(ngModel)]="account.rememberMe" name="rememberMe" labelPlacement="start">{{
            'REMEMBER_ME' | translate
          }}</ion-toggle>
        </ion-item>
      }
    </ion-list>
  </form>
</ion-content>

<ion-footer class="ion-no-border">
  <ion-toolbar>
    <ion-buttons>
      <div class="button-actions">
        <ion-button (click)="doLogin()" fill="solid" type="submit">
          @if (this.loading) {
            <ion-spinner color="light" name="crescent"></ion-spinner>
          } @else {
            <div>{{ 'LOGIN' | translate }}</div>
          }
        </ion-button>
      </div>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
