import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { jwtDecode } from 'jwt-decode';
import { Events } from '../services/events/events.service';

export const jwtValidationInterceptor: HttpInterceptorFn = (req, next) => {
  const events = inject(Events);

  const token = req.headers.get('Authorization')?.split(' ')[1];
  if (token) {
    const { exp } = jwtDecode(token);
    if (exp && Date.now() >= exp * 1000) {
      events.publish('auth:jwt-expired');
    }
  }

  return next(req);
};
